import InputWithTitle from "components/Inputs/InputWithTitle";
import React, { useEffect } from "react";

const VehicleNumbersofInput = ({
  vehicle_number,
  onChange,
  values,
  isCheck,
  onChecked,
  isErrorMessage,
}) => {
 
console.log(values,'values');

  return (
    <div className="form-group">
      {Array.from({ length: vehicle_number }, (_, index) => index + 1).map(
        (item, index_) => (
          <div key={index_}>
            <label className="text_field">Vehicle Registration Number:</label>
            <InputWithTitle
              value={values[index_]?.value}
              width={"100%"}
              borderRadius={"10px"}
              label={"Vehicle Registration Number"}
              padding={"10px 10px"}
              textWidth={"100%"}
              border={"1px solid #BFBFBF"}
              className={"d-flex flex-column align-items-center"}
              type={"text"}
              placeholder={`Vehicle ${item}`}
              onChange={(val) => onChange(val.target.value, index_)}
            />
            {isErrorMessage &&!values[item]?.value&& <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              {isErrorMessage}
            </p>}
            <div className="d-flex my-2">
              <input
                type="checkbox"
                checked={isCheck[item]?.isChecked}
                onChange={(val) => onChecked(val?.target?.checked, item - 1)}
              />
              <p style={{ paddingLeft: "20px" }}>Telemetics</p>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default VehicleNumbersofInput;
