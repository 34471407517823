import { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import SimpleButton from "components/Buttons/SimpleButton";
import ModalALert from "components/Modals/Modal";
import { validateEmail } from "utils/method";
import { useSendData } from "utils/customHooks";
import { bookDemoEmptyField } from "data/FormData";
import useMediaQuery from "utils/CustonHooks/UseDeviceResponsiveHook";

const Form = ({ boxShadow, borderRadius }) => {
  const matches = useMediaQuery("(min-width: 768px)");
  const [formHandler, setFormHandler] = useSendData();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    lookingFor: "",
    region: "United Kingdom",
    fleetSize: "",
    remarks: "",
  });

  const [isError, setIsError] = useState(false);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow only numbers for the phone field
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    setFormHandler({ ...formHandler, status: "" });
    setFormData({ ...formData, ...bookDemoEmptyField });
  }, [formHandler?.status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData?.name == "" ||
      formData?.phone == "" ||
      formData?.email == "" ||
      formData?.lookingFor == "" ||
      formData?.region == "United Kingdom" ||
      formData?.fleetSize == "" ||
      // formData?.remarks == "" ||
      !validateEmail(formData?.email)
    ) {
      setIsError(true);
    } else {
      const submit_data = {
        ...formData,
        status: 3,
        date: new Date(),
        form_name: "Request Your Demo",
      };
      const sheet_form_data = new FormData();
      Object?.keys(submit_data)?.forEach((item) => {
        sheet_form_data.append(
          item === "lookingFor"
            ? "LOOKING_FOR"
            : item === "region"
            ? "ZONE_REGION"
            : item === "fleetSize"
            ? "FLEET_SIZE"
            : item === "status"
            ? "status"
            : item === "form_name"
            ? "form_name"
            : item?.toLocaleUpperCase(),
          submit_data[item]
        );
      });
      setFormHandler((valuestoFormHandler) => ({
        ...valuestoFormHandler,
        email_data: submit_data,
        form_data: sheet_form_data,
        isSpinner: true,
      }));
    }
  };

  return (
    <>
      <form
        className="container"
        style={{
          boxShadow: boxShadow,
          borderRadius: borderRadius,
          padding: "15px 0px",
        }}
      >
        <div className="mb-3">
          <label className="form-label">Full Name *</label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Enter Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {isError && formData?.name == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Contact Number *</label>
          <input
            type="tel"
            inputMode="numeric"
            pattern="[0-9]*"
            className="form-control"
            name="phone"
            placeholder="Enter Contact Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {isError && formData?.phone == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Email *</label>
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Enter Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {isError && formData?.email == "" ? (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          ) : (
            !validateEmail(formData?.email) &&
            formData?.email !== "" && (
              <p
                style={{
                  textAlign: "left",
                  color: "red",
                  fontSize: 10,
                  margin: "10px 0px",
                }}
              >
                Enter Verified Email *
              </p>
            )
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Looking For *</label>
          <select
            className="form-select"
            name="lookingFor"
            value={formData.lookingFor}
            onChange={handleChange}
            required
          >
            <option value="">- Select -</option>
            <option value="Driver Checks">Driver Checks</option>
            <option value="Tyre/Wheel Management & Predictions">
              Tyre/Wheel Management & Predictions
            </option>
            <option value="Fuel & Adblue usage">Fuel & Adblue usage</option>
            <option value="Document Mangement">Document Mangement</option>
            <option value="T-Drive Your Documents Space Storage">
              T-Drive Your Documents Space Storage
            </option>
            <option value="Tachograph Analysis">Tachograph Analysis</option>
            <option value="Remote Tachograph Download">
              Remote Tachograph Download
            </option>
            <option value="Gap Analysis">Gap Analysis</option>
            <option value="Vehicle Tracking">Vehicle Tracking</option>
            <option value="Others">Others</option>
            {/* Add other options */}
          </select>
          {isError && formData?.lookingFor == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Zone/Region *</label>
          <input
            type="text"
            className="form-control"
            name="region"
            value="United Kingdom" // Set default value directly
            readOnly // Prevent users from modifying the value
          />
          {/* {isError && formData?.region == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )} */}
        </div>

        <div className="mb-3">
          <label className="form-label">Fleet size *</label>
          <select
            className="form-select"
            name="fleetSize"
            value={formData.fleetSize}
            onChange={handleChange}
            required
          >
            <option value="">- Select -</option>
            <option value="1-10">1-10</option>
            <option value="11-50">11-50</option>
            <option value="50-100">50-100</option>
            <option value=">100+">100+</option>
            {/* Add other options */}
          </select>
          {isError && formData?.fleetSize == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Remarks</label>
          <textarea
            className="form-control"
            name="remarks"
            placeholder="Enter Remarks"
            value={formData.remarks}
            onChange={handleChange}
          ></textarea>
          {/* {isError && formData?.remarks == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )} */}
        </div>
        <SimpleButton
          background={"#7fbcb1"}
          color={"white"}
          text={"Submit"}
          margin={"20px 0px"}
          width={matches ? "130px" : "100%"}
          border={0}
          isSpinner={formHandler?.isSpinner}
          textMargin={"auto"}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        />
      </form>
      <ModalALert
        isOpenModal={formHandler?.enableModal}
        animationFile={formHandler?.filetype}
        onClose={() => {
          setFormHandler({ ...formHandler, enableModal: false });
        }}
      />
    </>
  );
};

export default Form;
